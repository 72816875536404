.title-description {
  .title-text {
    font-family: "UnimedSlab-2020-bold", Arial, sans-serif;
    font-size: 1.375rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--green-dark-100);

    @media screen and (min-width: 768px) {
      font-size: 1.778rem;
      line-height: 1.38;
    }
  }

  .description-text {
    font-family: "UnimedSans-2020-regular", Arial, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.15px;
    color: var(--gray-100);

    @media screen and (min-width: 768px) {
      line-height: 1.39;
      letter-spacing: 0.25px;
    }
  }

  .number-text {
    font-family: "UnimedSans-2020-bold", Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--green-dark-100);

    @media screen and (min-width: 768px) {
      font-size: 1.333rem;
    }
  }

  &.primary {
    .title-text, li {
      color: var(--primary) !important;
    }

    .title-description {
      color: var(--gray-100) !important;
    }
  }

  &.primary.background {
    background-color: var(--primary) !important;

    .title-text, li {
      color: var(--white) !important;
    }

    .description-text {
      color: var(--white) !important;
    }
  }

  &.secondary {
    .title-text, li {
      color: var(--secondary) !important;
      padding: 0;
    }

    .description-text {
      color: var(--gray-100) !important;
    }
  }

  &.tertiary {
    .title-text, li {
      color: var(--green-dark-100) !important;
    }

    .description-text {
      color: var(--gray-100) !important;
    }
  }

  &.quartiary {
    .title-text, li {
      color: var(--purple) !important;
    }

    .description-text {
      color: var(--gray-100) !important;
    }
  }

  ul {
    padding-left: 1rem;
    margin: 0.5rem 0;
  }

  li {
    margin-bottom: 0.5rem;
  }
}
