.custom-table {
  border-color: var(--gray-300);
  border-style: solid;

  &--initial {
    border-collapse: initial;
  }

  &__row {
    border: 1px solid var(--gray-300) !important;
  }

  .tdBefore {
    color: var(--primary);
  }

  td.pivoted.custom-table__cell {
    @media screen and (max-width: 40em) {
      border-bottom: 1px solid #ccc !important;

      &:last-child {
        border-bottom: none !important;
      }
    }
  }

  .clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
