.gallery {
  display: none;

  &__block.video {
    margin: 1rem 1rem 0.5rem 1rem;
  }

  .xblock {
    iframe {
      width: 100%;
      border-radius: 16px;
    }
  }

  .xmasonry {
    margin: 0 -1rem -1rem -1rem;
  }

  @media screen and (min-width: 992px) {
    display: initial;
  }

  &__block {
    margin: 1rem;
  }

  &__image {
    width: 100%;
    border-radius: 16px;
  }
}
