@import './../../../styles/utils';

.mini-banner {
  .title-text {
    font-family: 'UnimedSlab-2020-regular', Arial, sans-serif;
    font-size: remMobile(26px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--primary);

    @media screen and (min-width: 768px) {
      font-size: remDesktop(32px);
    }
  }

  &__image.full-image {
    object-fit: contain;
  }

  .mini-banner-content {
    ul {
      margin: 0;
      padding-left: 20px;
    }

    li {
      font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
      line-height: 2.25;
    }
  }

  // Color themes

  &.primary {
    background-color: var(--white) !important;

    &-contrast {
      background-color: var(--gray-400) !important;

      &-darker {
        background-color: var(--gray-300) !important;
      }
    }

    .title-text {
      color: var(--primary) !important;
    }

    .mini-banner-content {
      p {
        color: var(--gray-100) !important;
      }

      ul li::marker,
      ul li {
        color: var(--primary) !important;
      }
    }
  }

  &.secondary {
    background-color: var(--white) !important;

    &-contrast {
      background-color: var(--gray-400) !important;
      .title-text {
        color: var(--secondary) !important;
      }
      .mini-banner-content {
        p {
          color: var(--gray-100) !important;
        }

        ul li::marker,
        ul li {
          color: var(--secondary) !important;
        }
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .title-text {
          color: var(--secondary) !important;
        }
        .mini-banner-content {
          p {
            color: var(--gray-100) !important;
          }

          ul li::marker,
          ul li {
            color: var(--secondary) !important;
          }
        }
      }
    }

    .title-text {
      color: var(--secondary) !important;
    }

    .mini-banner-content {
      p {
        color: var(--gray-100) !important;
      }

      ul li::marker,
      ul li {
        color: var(--secondary) !important;
      }
    }
  }

  &.tertiary {
    background-color: var(--white) !important;

    &-contrast {
      background-color: var(--gray-400) !important;
      .title-text {
        color: var(--green-dark-100) !important;
      }

      .mini-banner-content {
        p {
          color: var(--gray-100) !important;
        }

        ul li::marker,
        ul li {
          color: var(--green-dark-100) !important;
        }
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .title-text {
          color: var(--green-dark-100) !important;
        }

        .mini-banner-content {
          p {
            color: var(--gray-100) !important;
          }

          ul li::marker,
          ul li {
            color: var(--green-dark-100) !important;
          }
        }
      }
    }

    .title-text {
      color: var(--green-dark-100) !important;
    }

    .mini-banner-content {
      p {
        color: var(--gray-100) !important;
      }

      ul li::marker,
      ul li {
        color: var(--green-dark-100) !important;
      }
    }
  }

  &.primary-background {
    background-color: var(--primary) !important;

    .title-text {
      color: var(--white) !important;
    }

    .mini-banner-content {
      p {
        color: var(--white) !important;
      }
    }

    .footer-button {
      background-color: var(--secondary);
      border-color: var(--secondary);

      &:focus-within {
        outline: 2px solid blue;
      }
    }
  }

  &.secondary-background {
    background-color: var(--secondary) !important;

    .title-text {
      color: var(--white) !important;
    }

    .mini-banner-content {
      p {
        color: var(--white) !important;
      }
    }
  }

  &.tertiary-background {
    background-color: var(--green-dark-100) !important;

    .title-text {
      color: var(--white) !important;
    }

    .mini-banner-content {
      p {
        color: var(--white) !important;
      }
    }
  }

  &.quartiary-background {
    background-color: var(--green-dark-200) !important;

    .title-text {
      color: var(--white) !important;
    }

    .mini-banner-content {
      p:first-of-type {
        color: var(--success) !important;
      }

      p {
        color: var(--white) !important;
      }
    }
  }

  &.warning {
    background-color: var(--warning) !important;

    .title-text {
      color: var(--secondary) !important;
    }
  }
}
