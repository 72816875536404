.service-widget {
  &.primary {
    &-contrast {
      background-color: var(--gray-400);
    }

    .service-width__title-description .title-text {
      color: var(--primary);
    }
  }

  &.secondary {
    &-contrast {
      background-color: var(--gray-400);
      .service-width__title-description .title-text {
        color: var(--secondary) !important;
      }
    }

    .service-width__title-description .title-text {
      color: var(--secondary) !important;
    }
  }

  &.tertiary {
    &-contrast {
      background-color: var(--gray-400);
      .service-width__title-description .title-text {
        color: var(--purple) !important;
      }
    }

    .service-width__title-description .title-text {
      color: var(--purple) !important;
    }
  }

  &.quartiary {
    &-contrast {
      background-color: var(--gray-400);
      .service-width__title-description .title-text {
        color: var(--green-dark-100) !important;
      }
    }

    .service-width__title-description .title-text {
      color: var(--green-dark-100) !important;
    }
  }
}
