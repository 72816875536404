.half-banner {
  padding: 24px;
  border-radius: 16px;
  min-height: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100%;

  &.half-banner-col {
    height: 100%;
  }

  &.half-banner-row {
    height: auto;
  }

  .card-cta-header {
    display: flex;
    align-items: flex-end;

    &__image,
    i {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      vertical-align: middle;
    }

    h3 {
      margin-bottom: 0;
      font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
      font-size: 1.125rem;
      @media screen and (min-width: 768px) {
        font-size: 1.333rem;
      }
    }
  }

  p {
    font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
    @media screen and (min-width: 768px) {
      margin-bottom: 24px;
    }
  }

  .card-cta-btn {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      flex: 1 0 auto;
      align-items: flex-end;
    }
  }
}
