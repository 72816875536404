@import '../../../styles/sizes';

.description {
  &__table {
    width: 100%;

    @media (min-width: $grid-sm) {
      width: 540px;
    }

    @media (min-width: $grid-md) {
      width: 720px;
    }

    @media (min-width: $grid-lg) {
      width: 960px;
    }

    @media (min-width: $grid-xl) {
      width: 1140px;
    }

    .custom-table {
      @media (min-width: $grid-md) {
        width: max-content;
      }
    }
  }

  &__image {
    display: inline-block;
  }

  &__link {
    text-align: left;
    color: var(--primary);
  }
}
