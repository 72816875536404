.half-banners {
  .half-banners-additional-info {
    .additional-info-title,
    h3 {
      font-family: 'UnimedSans-2020-SemiBold', Arial, sans-serif;
      font-size: 1rem;

      &:before {
        content: '\e900';
        font-family: 'icomoon';
        margin-right: 8px;
        vertical-align: top;
      }
    }

    p {
      color: var(--gray-100);
    }
  }
  &.primary {
    background-color: var(--primary) !important;

    .title {
      color: var(--white) !important;
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .title {
        color: var(--primary);
      }

      &-darker {
        background-color: var(--gray-300) !important;
      }
    }
  }
  &.secondary {
    background-color: var(--secondary) !important;

    .title {
      color: var(--white) !important;
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .title {
        color: var(--secondary) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;
        .title {
          color: var(--secondary) !important;
        }
      }
    }
  }
  &.tertiary {
    background-color: var(--purple) !important;

    .title {
      color: var(--white) !important;
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .title {
        color: var(--purple) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;
        .title {
          color: var(--purple) !important;
        }
      }
    }
  }

  &.quartiary {
    background-color: var(--green-dark-100) !important;

    .title {
      color: var(--white) !important;
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .title {
        color: var(--green-dark-100) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;
        .title {
          color: var(--green-dark-100) !important;
        }
      }
    }
  }
}
