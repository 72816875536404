.ComboBox {
  &__combo-box {
    display: inline;
    font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.39;
    letter-spacing: 0.25px;
    color: var(--gray-100);
    margin-right: 16px;
  }

  &.__combo-box-link {
    display: inline;
    font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.39;
    letter-spacing: 0.25px;
    color: var(--gray-100);
    margin-right: 16px;
  }
}
