@import './../../../styles/utils';

.glossary-widget {
  .title {
    font-family: "UnimedSlab-2020-Bold", Arial, sans-serif;
    font-size: remMobile(22px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--purple) !important;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      font-size: remDesktop(32px);
      line-height: 1.38;
    }
  }

  .description {
    font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
    font-size: remMobile(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.15px;
    color: var(--gray-100);
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      font-size: remDesktop(18px);
      line-height: 1.39;
      letter-spacing: 0.25px;
    }
  }

  .link-list {
    padding: 0;
    margin: 0;

    .link-list-item {
      list-style: none;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
      
      a {
        font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
        font-size: remMobile(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.15px;
        color: var(--primary);

        @media screen and (min-width: 768px) {
          font-size: remDesktop(18px);
          line-height: 1.39;
          letter-spacing: 0.25px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.background {
    background-color: var(--gray-400);
  }

  &.primary {
    .title {
      color: var(--primary) !important;
    }

    &-contrast {
      background-color: var(--gray-400);

      .title {
        color: var(--secondary) !important;
      }
    }
  }

  &.secondary {
    .title {
      color: var(--secondary) !important;
    }
  }
}