.title-and-description {
  &.primary {
    &-background {
      background-color: var(--primary) !important;

      .title-text,
      .description-text,
      .external-link-button {
        color: var(--white) !important;

        &.primary {
          &-background {
            &:hover {
              color: var(--warning) !important;
            }
          }
        }

        a,
        .external-link-button {
          color: var(--white) !important;
          text-decoration: underline;
        }
      }
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      &-darker {
        background-color: var(--gray-300) !important;
      }
    }
  }

  &.secondary {
    .title-text {
      color: var(--secondary) !important;
    }

    &-background {
      background-color: var(--secondary) !important;

      .title-text,
      .description-text,
      .external-link-button {
        color: var(--white) !important;

        a,
        .external-link-button {
          color: var(--white) !important;
          text-decoration: underline;
        }
      }
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .title-text {
        color: var(--secondary) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .title-text {
          color: var(--secondary) !important;
        }
      }
    }
  }

  &.tertiary {
    .title-text {
      color: var(--green-dark-100) !important;
    }

    &-background {
      background-color: var(--green-dark-100) !important;

      .title-text,
      .description-text,
      .external-link-button {
        color: var(--white) !important;

        a,
        .external-link-button {
          color: var(--white) !important;
          text-decoration: underline;
        }
      }
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .title-text {
        color: var(--green-dark-100) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .title-text {
          color: var(--green-dark-100) !important;
        }
      }
    }
  }

  &.quartiary {
    .title-text {
      color: var(--purple) !important;
    }

    &-background {
      background-color: var(--purple) !important;

      .title-text,
      .description-text,
      .external-link-button {
        color: var(--white) !important;
      }

      .description {
        &-text {
          a,
          .external-link-button {
            color: var(--white) !important;
            text-decoration: underline;
          }
        }
      }
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .title-text {
        color: var(--purple) !important;
      }

      .description {
        &-text {
          a,
          .external-link-button {
            color: var(--purple) !important;
          }
        }
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .title-text {
          color: var(--purple) !important;
        }

        .description {
          &-text {
            a,
            .external-link-button {
              color: var(--purple) !important;
            }
          }
        }
      }
    }
  }

  &.warning {
    .title-text {
      color: var(--secondary) !important;
    }

    background-color: var(--warning) !important;

    .title-text,
    .description-text {
      color: var(--secondary) !important;

      a,
      .external-link-button {
        color: var(--secondary) !important;
        text-decoration: underline;
      }
    }
  }
}
