.definition-widget {
  &__row {
    border-bottom: 1px solid var(--gray-form);

    &:first-of-type {
      border-top: 1px solid var(--gray-form);
    }
  }

  &__table {
    thead {
      background-color: var(--primary);
      color: var(--white);

      th {
        padding: 26px 22px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
      }
    }

    tbody {
      tr {
        background-color: var(--gray-300);
        border: none;

        td {
          padding: 16px;
          color: var(--dark-contrast-color);
          font-size: 16px;

          a {
            text-decoration: underline;
          }
        }

        &:nth-child(even) {
          background-color: var(--gray-400);
        }
      }
    }
  }

  &.primary {
    background-color: var(--white);

    .description-text, .title-description .title-text {
      color: var(--primary);
    }

    .text {
      color: var(--gray-200) !important;
    }

    &-contrast {
      background-color: var(--gray-400);

      .description-text, .title-description .title-text {
        color: var(--primary);
      }

      .text {
        color: var(--gray-200) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .description-text, .title-description .title-text {
          color: var(--primary);
        }
      }
    }
  }

  &.secondary {
    background-color: var(--gray-400);

    .description-text, .title-description .title-text, .text-primary {
      color: var(--green-dark-100) !important;
    }

    .text {
      color: var(--gray-200) !important;
    }

    &-contrast {
      background-color: var(--gray-400);

      .description-text, .title-description .title-text, .text-primary {
        color: var(--secondary) !important;
      }

      .text {
        color: var(--gray-200) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;
  
        .description-text, .title-description .title-text, .text-primary {
          color: var(--secondary) !important;
        }

        .text {
          color: var(--gray-200) !important;
        }
      }
    }
  }

  &.tertiary {
    background-color: var(--white) !important;

    .description-text, .title-description .title-text, .text-primary {
      color: var(--green-dark-100) !important;
    }

    .text {
      color: var(--gray-200) !important;
    }

    &-contrast {
      background-color: var(--gray-400) !important;

      .description-text, .title-description .title-text, .text-primary {
        color: var(--green-dark-100) !important;
      }

      .text {
        color: var(--gray-100) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .description-text, .title-description .title-text, .text-primary {
          color: var(--green-dark-100) !important;
        }

        .text {
          color: var(--gray-200) !important;
        }
      }
    }

    &-background {
      background-color: var(--green-dark-100) !important;

      .description-text, .title-description .title-text, .text-primary {
        color: var(--white) !important;
      }

      .text {
        color: var(--white) !important;
      }
    }
  }

  &.quartiary {
    &-background {
      background-color: var(--green-dark-200) !important;
  
      .description-text, .title-description .title-text, .text-primary {
        color: var(--white) !important;
      }

      .text {
        color: var(--success) !important;
      }
    }
  }
}

