.PaginationButton {
  &.pagination-btn {
    background: var(--white);
    border-radius: 8px;
    border: none;
    min-width: 32px;
    height: 32px;
    margin: 8px 4px;
    color: var(--primary);
    font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
    font-size: 1rem;

    @media screen and (min-width: 700px) {
      min-width: 44px;
      height: 44px;
      font-size: 1rem;
    }
    &.disabled {
      color: var(--gray-100);
    }
    &.active,
    &:hover {
      background: #00875221;
    }

    &-previous {
      align-items: center;
      display: flex;
      padding-right: 12px;
    }
    &-next {
      align-items: center;
      display: flex;
      padding-left: 12px;
    }
  }
}
