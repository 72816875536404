.pin-utilizacao {
  &__table {
    display: block;
    overflow: auto;
    max-width: 1110px;
    width: 100%;

    .custom-table__cell > *,
    .custom-table__header {
      font-size: 0.66rem;
    }

    .custom-table__cell,
    .custom-table__header {
      padding: 0.5rem !important;
    }

    .custom-table__cell {
      height: 10rem;
    }
  }

  &__category {
    font-size: 0.66rem;
  }

  &__simple-result {
    input {
      border-radius: 4px;
      border: solid 1px var(--gray-100);
      height: 20px;
      width: 20px;
    }

    label {
      color: var(--gray-100);
      margin-left: 0.5rem;
      vertical-align: middle;
    }
  }
}
